@use '@/styles/utils/mixins.scss' as *;

.promotionBannerWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .bannerSwiper {
    .bannerSwiperWrap {
      position: relative;
      z-index: 2;
      background-color: $kiaMidnightBlack;
      @media screen and (max-width: 767px) {
        min-height: 450px;
      }
      @media screen and (max-width: 576px) {
        min-height: auto;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 85%;
        background-color: rgba(5, 20, 31, 0.2);
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(5, 20, 31, 0) 55%,
          $kiaMidnightBlack 100%
        );
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
      .bannerImage {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        @media screen and (max-width: 991.99px) {
          position: static !important;
          height: auto !important;
          min-height: 300px;
        }

        @media screen and (max-width: 576px) {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          @media screen and (max-width: 991.99px) {
            position: static !important;
          }
        }
      }
    }
    .swiper-button-prev {
      left: 24px;
    }
    .swiper-button-next {
      right: 24px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      font-size: 0;
      width: 48px;
      height: 48px;
      margin-top: -26px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 48px;
      height: 48px;
    }
    .swiper-button-prev::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M29.3334 23.7865H21.2201L24.9467 20.0598L24.0001 19.1198L18.6667 24.4531L24.0001 29.7865L24.9401 28.8465L21.2201 25.1198H29.3334V23.7865Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    .swiper-button-next::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M24 19.1198L23.06 20.0598L26.78 23.7865H18.6666V25.1198H26.78L23.06 28.8465L24 29.7865L29.3333 24.4531L24 19.1198Z' fill='white'/%3E%3C/svg%3E");
    }
  }
}
