@use '@/styles/utils/mixins.scss' as *;
.filterWrapper {
  position: sticky;
  top: 80px;
  z-index: 99;
  background-color: $kiaMidnightBlack;
  padding: 16px 0;

  .menuButton {
    display: flex !important;
    gap: 8px;
    align-items: center;
    justify-content: center;
    background-color: $keyline !important;
    color: $kiaMidnightBlack !important;
    border: 1px solid $keyline !important;
    border-radius: 38px !important;
    padding: 12px 24px !important;
    font-size: 16px !important;
    height: auto !important;
    min-height: 44px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    text-align: center;
    transition: all 450ms ease-in-out;
    width: 160px !important;
    white-space: nowrap;

    @include mob-tab() {
      width: auto !important;
      min-width: 120px !important;
      min-height: 40px !important;
      font-size: 14px !important;
      padding: 8px 16px !important;
    }

    @include des() {
      width: 200px !important;
      font-size: 18px !important;
    }

    &:hover {
      background-color: $kiaPolarWhite !important;
    }

    &.active {
      background-color: #37434c !important;
      color: #ffffff !important;
      position: relative;
      border: 3px solid #ffffff !important;
      box-shadow: 0 0 0 1px #37434c;

      svg {
        font-size: 14px;
        @include mob-tab() {
          font-size: 12px;
        }
      }
    }
  }

  .filterMenuBar {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .filterWrapper {
    padding: 12px 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .menuButton {
      min-width: 110px !important;
      min-height: 36px !important;
      font-size: 13px !important;
      padding: 8px 12px !important;

      &.active {
        border-width: 2px !important;
      }
    }
  }
}

.menuLink {
  font-family: 'KiaSignature';
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: $kiaMidnightBlack;
  padding: 20px 24px;
  text-align: left;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $kiaSilver;
  }
}
